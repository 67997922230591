import React from 'react'
import Layout from '../shared/layout'
import './style.scss'

const Blog = () => {
  return (
    <Layout>
      This be where the blog goes
    </Layout>
  )
}

export default Blog
