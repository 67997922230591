import React from 'react'
import Layout from '../shared/layout'
import './style.scss'

const Tutorials = () => {
  return (
    <Layout>
    </Layout>
  )
}

export default Tutorials